
import { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            alert('Veuillez compléter le reCAPTCHA.');
            return;
        }
        try {
            const response = await axios.post('https://welcometothemonkeys.com/send-email.php', { ...formData, recaptchaToken });
            console.log(response.data);
            alert('Message envoyé avec succès!');
        } catch (error) {
            console.error(error);
            alert('Une erreur s\'est produite. Veuillez réessayer.');
        }
        console.log(formData);
    };

    const handleRecaptcha = async () => {
        const token = await window.grecaptcha.execute('6LeerBsqAAAAABjCFdSisQ4IxJjW39BA_LQdsDnC', { action: 'submit' });
        setRecaptchaToken(token);
    };




    return (
        <section id="contact">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col>
                                <h2>CONTACT</h2>
                            </Col>
                        </Row>
                        <Row className="pt-5">
                            <Col lg={7}>
                                <Row>
                                    <Col lg={12}>
                                        <p>Une question ? Privatisation ? Ou tout autre projet ? C’est par ici !</p>
                                    </Col>
                                    <Col lg={12}>
                                        <Form onSubmit={handleSubmit} className="contact-form">
                                            <Form.Group controlId="formName">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Nom"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formEmail">
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formMessage">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    placeholder="Message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <button type="submit" className="btn-submit" onClick={handleRecaptcha}>
                                                Envoyer
                                            </button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} className='offset-1 desktop'>
                                <hr className="separateur-contact"></hr>
                            </Col>
                            <Col lg={3} className='desktop'>
                                <Row className="container-contact-info"> 
                                    <Col lg={12} className="adresse">
                                        <h3 className="contact-sstitre">ADRESSE</h3>
                                        <a target='_blank' rel='noreferrer' href="https://maps.app.goo.gl/ZJST66GjcNqYhGsTA">
                                            <span className="place-de-lglise1">290 Quai la Pérouse,<br/>06700 Saint-Laurent-du-Var</span>
                                        </a>
                                    </Col>
                                    <Col lg={12} className="support">
                                        <h3 className="contact-sstitre">SUPPORT</h3>
                                        <div>
                                            <a href="tel:+33664755538"><span className="contact-info">M / +33 6 64 75 55 38</span></a>
                                            <a href="mailto:contact@welcometothemonkeys.com"><span className="contact-info">E / contact@welcometothemonkeys.com</span></a>
                                        </div>
                                    </Col>
                                    <Col lg={12} className="ouverture">
                                        <h3 className="contact-sstitre">OUVERTURE</h3>
                                        <div>
                                            <span>Lundi / vendredi</span>
                                            <span>17h - 2h30</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </section>
    );

}

export default Contact;