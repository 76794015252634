import React, { useEffect } from "react";
import "../css/loader.css";
// import "../js/particles-config.js";
import anime from 'animejs';

const Loader = () => {

    useEffect(() => {
        const textWrapper = document.querySelector('.loader-text');
        if (textWrapper) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
      
          anime.timeline({ loop: false })
          .add({
              targets: '.loader-text .letter',
              translateY: [-100, 0],
              opacity: [0, 1],
              easing: 'easeInOutExpo',
              duration: 1400,
              delay: (el, i) => 30 * i
          })
          .add({
              targets: '.loader-text .letter',
              translateX: [0, -30],
              rotate: [0, 360],
              color: ['#fff', '#134DD2'],
              opacity: [1, 0],
              easing: 'easeInOutExpo',
              duration: 1200,
              delay: (el, i) => 30 * i
          });
        }
    });


    return (
        <section>
            <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>
            <div id="particles-js" className="particles-container">
                <div className="loader">
                <h1 className="loader-text">welcome to the monkeys</h1>
                </div>
            </div>
        
      </section>
    );
}


export default Loader;