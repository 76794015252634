import { Container, Row, Col } from 'react-bootstrap';
import Singe from "../assets/images/Banner/4.svg";




const Banner = () => {
    return (
        <section className='banner' id='banner'>
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center img-container">
                        <img src={Singe} alt='banner'/>
                    </Col>
                </Row>
            </Container>
        
        </section>
    );
}


export default Banner;

