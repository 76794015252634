import { Col, Container, Row } from "react-bootstrap";

import imgFond from "../assets/images/Ce/img-fond-ce.png";
import logo from "../assets/images/Ce/3.svg";


import ThemeProvider from 'react-bootstrap/ThemeProvider'

const CustomBreakpoints = {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
};

const Ce = () => {
    return (
        <section id="ce">
            <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
                <Container>
                    <Row>
                        <Col xs={12} lg={6} className="d-flex justify-content-center flex-column ">
                            <Row>
                                <Col lg={12} xs={12} className="d-flex align-items-center">
                                    <Row className="d-flex align-items-center section-header pb-5">
                                        <Col lg={2} md={2} xs={3}>
                                            {/* <img src={logo} alt="logo singe 3" className="logo-singe-3"/> */}
                                            <img src={logo} alt="logo singe 3"/>
                                        </Col>
                                        <Col lg={8} md={8} xs={8} className="d-flex flex-column ">
                                            <h2>C.E</h2>
                                            <h3>UN LIEU IDÉAL POUR VOS ÉVÉNEMENTS D’ENTREPRISE</h3>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="pt-5">
                                <Col>
                                    <p>
                                        NOTRE BAR EST LE LIEU PARFAIT POUR VOS ÉVÉNEMENTS D’ENTREPRISE.
                                    </p>
                                    <p>
                                        QUE CE SOIT POUR DES AFTERWORKS, DES SOIRÉES D’ÉQUIPE OU DES SÉMINAIRES, NOUS OFFRONS UN CADRE CHALEUREUX ET DES SERVICES SUR MESURE.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-start pt-5 container-contact-btn">
                                    <a href="/#contact" className="l-btn-contact">
                                        <div className="btn-contact">
                                            <span>CONTACT</span>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        
                        </Col>
                        <Col xs={12} lg={6} className="img-ce pt-5">
                            <img src={imgFond} alt=""/>
                        </Col>
                    </Row>
                </Container>
            </ThemeProvider>
        </section>
    );
}

export default Ce;